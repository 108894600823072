import React, { Component } from 'react';
import './App.css';
import Website from './Website'

import Grid from '@material-ui/core/Grid';
import SimpleAppBar from './SimpleAppBar';

class App extends Component {

  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  renderMobile() {
    return {
      backgroundColor: '#fdf6e3',
      paddingBottom: '108px'
    }
  }

  renderDesktop() {
    return {
      backgroundColor: '#fdf6e3',
      paddingBottom: '184px'
    }
  }

  render() {

    const { width } = this.state;
    const isMobile = width <= 500;

    return (
      <div style={isMobile ? this.renderMobile() : this.renderDesktop()}>
        <SimpleAppBar />
        <Grid container>
          <Grid
            container
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Website />

            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default App;
