import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import Android from '@material-ui/icons/Android';
import SvgIcon from '@material-ui/core/SvgIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = theme => ({
  card: {
    maxWidth: 400,
    marginTop: 48,
    marginLeft: 16,
    marginRight: 16
  },
  media: {
    height: 0,
    marginLeft: '16px',
    marginRight: '16px',
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  android: {
    color: "#a4c639"
  },
  linkedin: {
    color: "#000000"
  },
  github: {
    color: "#000000"
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {

  },
});

class Website extends React.Component {
  messagesEnd;
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
    setTimeout(this.scrollToBottom, 200)
  };

  scrollToBottom() {
    console.log(document)
    const element = document.getElementById("scroll-to-bottom-div")
    console.log(element)
    element.scrollIntoView({behavior: 'smooth'});
  }

  androidOnClick() {
    window.open("https://play.google.com/store/apps/developer?id=ekeitho")
  }

  linkedinOnClick() {
    window.open("https://www.linkedin.com/in/ekeitho/")
  }

  githubOnClick() {
    window.open("https://github.com/ekeitho")
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar src="./lyft.png" className={classes.avatar}>

              </Avatar>
            }
            // action={
            //   <IconButton>
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title="Keith Abdulla"
            subheader="Software Engineer, Android"
          />
          <CardMedia
            className={classes.media}
            image="me.jpg"
            title="Paella dish"
          />
          <CardContent>
            <Typography component="p">
              given a perfect bar, jacob's wonderbar and a challenging problem, i feel set up for success! i’m an open source contributor, mobile developer, avid rock climber, and a lifetime learner. currently taking a specialization on coursera for music production. i was born in texas, grew up in las vegas, and have california roots, where I graduated from cal poly san luis obispo.
          </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing>
            <IconButton onClick={this.androidOnClick} aria-label="Android URL">
              <Android className={classes.android} />
            </IconButton>

            <IconButton onClick={this.githubOnClick}>
              <SvgIcon className={classes.github}>
                <path d="M10.854,2.064C6.301,2.57,2.595,6.258,2.069,10.808c-0.547,4.729,2.21,8.896,6.26,10.495  C8.651,21.431,9,21.176,9,20.83v-1.58c0,0-0.375,0.125-0.875,0.125c-1.399,0-1.98-1.248-2.125-1.875  c-0.088-0.382-0.318-0.744-0.565-0.965C5.14,16.272,5.002,16.271,5,16.181C4.996,15.992,5.253,16,5.375,16  C6,16,6.474,16.665,6.694,17.009C7.239,17.858,7.824,18,8.125,18c0.375,0,0.699-0.056,0.922-0.164C9.15,17.11,9.473,16.463,10,16  c-2.345-0.469-4-1.814-4-4c0-1.126,0.452-2.161,1.205-2.997C7.128,8.785,7,8.344,7,7.625C7,7.15,7.033,6.567,7.25,6  c0,0,1.426,0.01,2.771,1.284C10.642,7.103,11.306,7,12,7s1.358,0.103,1.979,0.284C15.324,6.01,16.75,6,16.75,6  C16.968,6.567,17,7.15,17,7.625c0,0.775-0.103,1.227-0.166,1.422C17.564,9.875,18,10.894,18,12c0,2.186-1.655,3.531-4,4  c0.626,0.55,1,1.351,1,2.25v2.58c0,0.346,0.348,0.6,0.67,0.473C19.377,19.84,22,16.227,22,12C22,6.1,16.89,1.393,10.854,2.064z">></path>
              </SvgIcon>
            </IconButton>
            <IconButton onClick={this.linkedinOnClick}>
              <SvgIcon className={classes.linkedin}>
                <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z"></path>
              </SvgIcon>
            </IconButton>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                fun facts:
              </Typography>
              <Typography paragraph>
                1. i've never owned a car in my life
            </Typography>
              <Typography paragraph>
                2. from a multicultural family
            </Typography>
              <Typography paragraph>
                3. first generation to attend & finish college
            </Typography>

            </CardContent>
          </Collapse>
        </Card >
        <div id="scroll-to-bottom-div">
        </div>
      </div>
    );
  }
}

Website.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Website);